<template>
    <div v-if="!$route.path.includes('new')">
        <span @click="show_receipt_modal()" class="px-3 py-2 rounded is-clickable" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background: rgb(255, 255, 255);" >
            <i class="fa fa-print"></i>
        </span>
    </div>
</template>

<script setup>
    import { useProcessStore } from '@/stores/process'
</script>

<script>
import { $vfm } from 'vue-final-modal'
import { defineAsyncComponent } from 'vue'


export default {
    methods: {
        show_receipt_modal(){
            var params = {
                step: useProcessStore().current_step,
                'some data': 123
            }
            $vfm.show({
                component: defineAsyncComponent(
                () => import("./receipt_modal.vue")
                ), params
            })
        }
    },
}
</script>
