<template>
    <div>
        <span @click="toggle_reject_modal()" class="px-3 py-2 mr-2 rounded is-clickable" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background: rgb(255, 255, 255);" >
            <i class="fa-solid fa-ban text-red-500"></i>
        </span>
        <!-- BLOCK: Product service modal -->
        <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center"
            v-if="show_reject_modal" @click.self="toggle_reject_modal()">
            <div class="p-0 bg-white mt-20 flex-col products-search">
                <div class="p-2 bg-slate-100 text-center">
                    Refuzo shitjen 
                </div>
                <div class="p-2 flex flex-col text-14px">
                    <div class="flex flex-col mx-5 mb-2">
                        <span> Arsyet </span>
                        <select v-model="reject_info.reason" class="border rounded">
                            <option v-for="(reason, index) in rejecton_reasons" :key="index"
                                :value="reason.guid">{{reason.text}}</option>
                        </select>
                    </div>
                    <div class="flex flex-col mx-5">
                        <span>Shenime</span>
                        <textarea v-model="reject_info.text"
                            class="border rounded px-2" rows="4"></textarea>
                    </div>
                </div>
                <div class="flex p-2 border-t">
                    <a @click.prevent="save_reject()" class="w-full border rounded text-center bg-red-300 border-red-600" href="">
                        Refuzo
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "@/services/Api";
import ConfigurationService from "@/services/Configuration.js"
import { useProcessStore } from "@/stores/process";
export default {
    data() {
        return {
            rejecton_reasons: [],
            show_reject_modal: false,
            reject_info: {}
        }
    },
    methods: {
        async toggle_reject_modal(){
            this.show_reject_modal = !this.show_reject_modal
            if(this.show_reject_modal){
                this.rejecton_reasons = await ConfigurationService.getRejectionReasons()
            }
        },
        save_reject(){
            if(!this.reject_info.reason && !this.reject_info.reason){
                this.$toast.error('Zgjidhni nje nga arsyet ose shkruani nje arsye tek shenimet.')
                return
            }
            Api(true).post("/processes/reject", {
                reject_info: this.reject_info,
                process: useProcessStore().process
            }).then(res => {
                if(res.data == 1){
                    this.$toast.success('shitja u refuzua')
                    window.location.reload();
                } 
            })
        }
    }
}
</script>
<style >
    .products-search {
        min-width: 80%;
        margin-left: 30px;
        margin-right: 30px;
    }
</style>