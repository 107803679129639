import Api from "@/services/Api";

export default {
    async getWarehouses() {
        var warehouses = []
        await Api(true).post("warehouse/list", {})
        .then(r => {
            warehouses = JSON.parse(r.data).map(e => {
                e.id = e.guid
                e.label = e.name
                e.collapsed = true;
                return e
            })
        })
        return warehouses
    },

    async createWarehouse(warehouse) {
        await Api(true).post("warehouse/create", warehouse)
        .then(r => {
            warehouse = r.data
        })
        return warehouse
    },

    async editWarehouse(warehouse) {
        await Api(true).post("warehouse/edit", warehouse)
        .then(r => {
            warehouse = r.data
        })
        return warehouse
    },

    async getWarehouseFacilities() {
        var facilities = []
        await Api(true).post("warehouse_facility/list", {})
        .then(r => {
            facilities = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return facilities
    },

    async getWarehouseMainUnits() {
        var main_unit = []
        await Api(true).post("main_unit/list", {})
        .then(r => {
            main_unit = r.data.map(e => {
                e.label = e.name
                return e
            })
        })
        return main_unit
    },

    async getWarehouseData(guid) {
        var warehouse_data = []
        await Api(true).post("warehouse/get/data", {'guid': guid})
        .then(r => {
            warehouse_data = r.data[0]
        })
        return warehouse_data
    },
    async getTransitWarehouses() {
        var warehouses = []
        await Api(true).post("warehouse/transit/list", {})
        .then(r => {
            warehouses = r.data
            // .map(e => {
            //     e.id = e.guid
            //     e.label = e.name
            //     e.collapsed = true;
            //     return e
            // })
        })
        return warehouses
    },
    async getSingleWarehouseTransit(warehouse) {
        var warehouses = []
        await Api(true).post("warehouse/transit/single", {warehouse})
        .then(r => {
            warehouses = r.data
            // .map(e => {
            //     e.id = e.guid
            //     e.label = e.name
            //     e.collapsed = true;
            //     return e
            // })
        })
        return warehouses
    }
    
}
