<template>
    <div class="is-flex">
        <div v-for="(service_type_group, index) in Object.keys(product.extra_services_types)" :key="index" 
            class="relative">
            <div @click="toggle_service_dropdown(service_type_group)" 
                class="icon-wrap relative is-flex is-justify-content-center is-align-items-center mr-2 px-2 py-1 is-clickable">
                <span v-if="product.extra_services_types[service_type_group].length > 1" class="mr-1 text-xs">
                    {{product.extra_services_types[service_type_group].length}}
                </span>
                <i :class="[product.extra_services_types[service_type_group][0].service_type_icon]" class="fa-sm text-green-600 text-sm"></i>
            </div>
            <div v-if="show_service_dropdown == service_type_group" class="absolute absolute-pannel" style="">
                <div class="is-flex bg-gradient-to-r from-slate-50 to-slate-200 p-1 pl-2">
                    {{product.extra_services_types[service_type_group][0].service_type_name}}
                    <button @click.prevent="show_service_dropdown = null" class="delete ml-auto is-small"></button>
                </div>
                <div v-for="service in product.extra_services_types[service_type_group]" :key="service"
                    class="p-2" style="width: max-content; min-width:100%;">
                    <div>
                    </div>
                    <div class="is-flex mb-2 is-align-items-center">
                        <a v-if="useProcessStore().current_step.can_edit_services == 1" @click.prevent="remove_document_service_click(service, product)" href=""> 
                            <i class="fa-solid fa-xmark text-red-800  text-xs" style=" "></i>
                        </a>
                        <span class=" mr-2 ml-1 "> {{service.service_name}} </span>
                        <div v-if="useProcessStore().current_step.can_edit_services == 1" class="is-flex">
                            <input v-model="service.price" @keyup.enter="save_document_service_click(service, product)" class="input ml-auto mr-2" 
                                style="width:100px;" type="text" placeholder="">
                            <!-- :name="service.name" -->
                            <Datepicker
                                v-model="service.requested_for"
                                :format="'dd/MM/yyyy HH:mm'"
                                @update:modelValue="save_document_service_click(service, product)"
                                class="datepicker-wrap"
                            />
                            <div class="relative ml-2" >
                                <textarea v-model="service.note" rows="2" class=" textarea is-small border" placeholder="Shenime"></textarea>
                                <a @click.prevent="save_document_service_click(service, product)" href=""
                                    class="button is-small is-success absolute opacity-70 hover:opacity-100"
                                    style="bottom:5px; right:5px;"
                                     >
                                    <i class="fa-solid fa-check"></i>
                                </a>
                            </div>
                        </div>
                        <div v-else>
                            <span class="px-2 border-r border-l">
                                {{useProcessStore().price_filter(service.price)}}
                            </span>
                            <span class="pl-2">
                                {{service.requested_for ? useProcessStore().date_filter(service.requested_for) : ''}}
                            </span>
                            <span class="px-2 border-l ml-2" style="font-size:12px;">
                                {{ service.note ? service.note : '' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineProps, ref } from 'vue'
    import { useProcessStore } from '@/stores/process'
    import Datepicker from "@vuepic/vue-datepicker";
    import "@vuepic/vue-datepicker/dist/main.css";
    import { inject } from "vue";
    const toast = inject("toast");
    // import { useRoute } from 'vue-router'
    // const route = useRoute()


    /* eslint-disable */
    const props = defineProps(['product'])

    let show_service_dropdown = ref(null)

    function toggle_service_dropdown(a){
        if(show_service_dropdown.value == a)
            show_service_dropdown.value = null
        else
            show_service_dropdown.value = a
        
    }
    function remove_document_service_click(service, product){
        useProcessStore().remove_document_service(service, product).then(r => {
            if(r.data.deleted){

                // Get the document 
                // useProcessStore().get_process_document(route.params.document_id)

                // Toast success
                toast.show('Sherbimi ' + r.data.deleted.service_name + ' u fshi me sukses.', 
                    { ...{ type: 'success', duration: 4000 } })

                // TO DO: Show changes in frontend
                // console.log('Update Item' , r.data)
                // useProcessStore().get_document_item(product)
            }
        })
    }
    
    function save_document_service_click(service, product){
        useProcessStore().save_document_service(service, product).then(r => {
            // Toast success
            toast.show('Ndryshimi u ruajt me sukses.', { ...{ type: 'success', duration: 4000 } });
        })
    }

</script>
<style scoped>
    .icon-wrap{
        border:solid 1px #dedede;
        border-radius:5px;
        
    }
    .absolute-pannel{
        z-index: 999; 
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background:#fff;
    }
    .datepicker-wrap{

    }
    
</style>
