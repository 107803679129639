<template>
    <div 
      class="border rounded p-2 border-teal-400 absolute text-white "
      style="background: rgb(16 31 43); z-index: 99; width: max-content;">
      <div class="is-flex">
        <p class="is-flex-grow-1"> Aksesore ekskluzive</p>
        <span @click.prevent="close_dropdown()" class="pt-2 pl-2 ">
          <span class="is-clickable is-flex is-align-items-flex-start "><i class="fa-solid fa-xmark fa-2xs"></i></span>
        </span>
      </div>

        <div v-for="(accessory, index) in useProcessStore().product_exclusive_accessories" :key="index"
          @click.prevent="add_product_extra(accessory) "
            class="is-flex border rounded px-1 mb-2 is-clickable">
            <div class="is-flex-grow-1 is-flex is-flex-direction-column">
              <div>
                <span v-if="location == 'search'" class="mr-1">
                  <i v-if="accessory.is_checked" class="fa-regular fa-square-check"></i>
                  <i v-else class="fa-regular fa-square"></i>
                </span>
                <span>
                  {{accessory.name}}
                </span>
              </div>
              <div>
                {{accessory.description}}
              </div>
            </div>
            <div class="pl-3 is-align-self-center">
              <span class="bg-teal-500 px-1 rounded">
                {{accessory.price}} Lek
              </span>
            </div>
        </div>
        <!-- <pre> {{useProcessStore().product_exclusive_accessories}} </pre> -->
    </div>
</template>

<script setup>
  import { useProcessStore } from '@/stores/process'
  import { useSearchProcess } from '@/stores/search_process'
</script>

<script>
  // import { useSearchProcess } from '@/stores/search_process'
  export default {
    props: [ "entity", "is_visible", "selected_product" , 'location'],
    created(){
      if(this.is_visible){
        useProcessStore().product_exclusive_accessories = []
        useProcessStore().get_explusive_entity_data(this.entity, this.selected_product)
      }
    },
    methods: {
      async add_product_extra(accessory){
        if(this.location == 'search'){
          useSearchProcess().add_extra(this.selected_product, accessory, 'exclusive_accessories')
        }
        else if(this.location == 'product_list'){

          // Add accessory to product list
          await useProcessStore().add_product_to_list({...accessory, amount: 1, reference_id: this.selected_product.detail_id})
          .then(async () => {
            //Close exclusive dropdown
            this.close_dropdown()
            // Push new item
            // useProcessStore().product_list.unshift(res.data.new_item) 

            // Get all document
            await useProcessStore().get_process_document(this.selected_product.document_id)
            // Toaster
            this.$toast.show('Produkti u shtua me sukses.', { ...{ type: 'success', duration: 4000 } });
          })
          console.log('TODO: Save the accessory' )
        }

      },
      add_product_accessory(accessory){

        console.log('add acc', accessory, this.selected_product)
      },
      close_dropdown(){
        this.$emit("close_exclusive_dropdown", { product: this.selected_product});
      }
      // get_explusive_entity_data(entity){ }
    },

  }
</script>

