<template>
    <div v-if="!$route.path.includes('new')">
        <span @click="show_scal_modal()" class="px-3 py-2 rounded is-clickable ml-2" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            background: rgb(255, 255, 255);" >
            <i class="fa-solid fa-barcode"></i> 
            <i class="fa fa-asterisk text-red-500 ml-2" v-if="products_with_no_serial.length > 0"></i>
            <i class="fa fa-check text-green-500 ml-2" v-else></i>
        </span>
        <!-- Scan Modal -->
        <div v-if="useProcessStore().toggle_scan_modal" @click.self="useProcessStore().toggle_scan_modal = false"
            class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center flex items-center justify-center" 
            style="z-index: 9;">
            <div class="bg-white flex-col w-5/6 mx-3 rounded overflow-y"
                style="max-height:calc(100vh - 75px); overflow-y:scroll;">
                <!-- Modal header -->
                <div class="p-2 border-b text-center flex justify-between items-center">
                    <div class="w-1/3"></div>
                    <div class="w-1/3 text-center font-semibold"> Skano produktet </div>
                    <div class="w-1/3"></div>
                </div>
                <div class=" py-1">
                    <div v-for="product in useProcessStore().product_list" :key="product.guid"
                        class="pl-3 mb-2">
                        <div class="flex justify-between items-end w-full border-b p-4">
                            <div>{{product.name}}</div>
                            <div v-if="!product.edit_serial">
                                <span> {{product.serial_number}} </span>
                                <i @click="toggle_edit_serial(product)" class="fa-regular fa-pen-to-square ml-2"></i>
                            </div>
                            <div v-if="product.edit_serial" class="w-1/3 relative flex" >
                                    <!-- :ref="'serial_input' + product.detail_id" -->
                                <input @keyup.enter="start_save_pdi_serial(product)"
                                    v-model="product.serial_number"
                                    :ref="'serial_input_' + product.detail_id"
                                    class="border-t border-b border-l rounded-l px-1 text-right pl-5">
                                <span class="fa-solid fa-barcode absolute left-2 top-1/2 transform -translate-y-1/2 "></span>
                                <a @click.prevent="start_save_pdi_serial(product)" class="bg-teal-300 border rounded-r px-2 flex justify-center items-center text-white hover:text-white" href="">
                                    <i class="fa-solid fa-check"></i>
                                </a>
                                <a @click.prevent="clear_serial_number(product)" class="bg-red-300 border rounded-r flex justify-center items-center  border-gray-50 p-4 text-white hover:text-white" href="">
                                    <i class="fa-solid fa-xmark"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useProcessStore } from '@/stores/process'
</script>

<script>
export default {
    methods: {
        show_scal_modal(){
            useProcessStore().toggle_scan_modal = !useProcessStore().toggle_scan_modal
        },
        async start_save_pdi_serial(product){
            var duplicated = 0
             useProcessStore().product_list.map(p => {
                if(p.serial_number == product.serial_number){
                    duplicated = duplicated + 1
                }
            })
            if(duplicated > 1){
                product.serial_number = null
                this.$toast.error('Nuk mund te vendosni te njejtin numer serial me shume se 1 here!')
                return
            }
            var a = await useProcessStore().save_pdi_serial(product)
            if(a.message == 'success'){
                this.$toast.success('Numri serial u ruajt me sukses!')
            } else {
                this.$toast.error('Numri serial nuk u ruajt!')
            }
        },
        async clear_serial_number(product){
            product.serial_number = null
            await useProcessStore().save_pdi_serial(product)
            
        },
        toggle_edit_serial(product){
            useProcessStore().product_list = [...useProcessStore().product_list.map(p => {
                if(p.detail_id == product.detail_id){
                    p.edit_serial = true
                }
                return p
            })]
            // put focus
            setTimeout(() => {
                this.$refs['serial_input_' + product.detail_id][0].focus()
                // 'serial_input_' + product.detail_id
            }, 500)
        }
    },
    computed: {
      // products with no serial number 
      products_with_no_serial(){
          return useProcessStore().product_list.filter(p => p.serial_number == null || p.serial_number == '')
      }
    }
}
</script>
