<template>
  <!-- POS -->
  <div v-if="party == 1" class=" bg-gray-50 text-black is-flex is-justify-content-space-between is-align-items-center border border-gray-200 px-4 shadow-md shadow" 
    :class="{'is-flex-direction-row-reverse': type == 'destination'}">

    <div class="font-bold is-uppercase is-flex  is-align-items-center text-gray-400 p-3 border-r"
      :class="[type == 'origin' ? 'is-flex-direction-row-reverse border-r ': 'border-l']">
      <span> POS </span>
      <i class="text-slate-400 fa-solid fa-shop mx-2"></i> 
    </div>
    <a href="" @click.prevent="party_set[type] = (useProcessStore().process?.document_data?.completed != 1 && (type == 'origin' && useProcessStore().product_list.length == 0) ) ? !party_set[type] : false" v-if="!party_set[type]" class="p-3">
      <span class="mx-3"
        v-if="useProcessStore().process.document_data?.[type + '_name']">{{ useProcessStore().process.document_data?.[type + '_name'] }}</span>
      <i class="fa fa-caret-down mt-2 mx-2 text-gray-300" ></i>
    </a>

    <div class=" is-flex p-3 w-full" v-if="party_set[type] == true"
      :class="{'is-flex-direction-row-reverse': type == 'destination'}">
      <div class="select is-small is-flex-grow-1">
        <select v-model="useProcessStore().process[type]" @change="save_document_party_return(type, party)"
          class="w-full">
          <option :value="null">Zgjidh POS</option>
          <option v-for="pos in  useProcessStore().assigned_pos_list" :key="pos.guid" :value="pos.guid">
            {{ pos.name }}
          </option>
        </select>
      </div>
      <a href="" @click.prevent="party_set[type] = false">
        <i class="fas fa-caret-up mt-2 mx-2"></i>
      </a>
    </div>

  </div>
  <!-- WAREHOUSE -->
  <div v-else-if="party == 2">
    <!-- <pre>{{useProcessStore().warehouse_types}}</pre> -->
       <div  class="bg-gray-50 text-black is-flex is-justify-content-space-between is-align-items-center sm:flex-column md:flex-row border border-gray-200 px-2 shadow-md shadow"
      :class="{'is-flex-direction-row-reverse': type == 'destination'}" >

      <div class="font-bold is-flex is-align-items-center is-uppercase  text-gray-400 p-3 border-l"
        :class="[type == 'origin' ? 'is-flex-direction-row-reverse border-r ': 'border-l']" >
        <span> Magazina </span>
        <i class="text-slate-400 fa-solid fa-warehouse mx-2"></i>
      </div>
      <a href="" @click.prevent="party_set[type] = (useProcessStore().process?.document_data?.completed != 1 && ((type == 'destination' && useProcessStore().product_list.length == 0) || type == 'origin')) ? !party_set[type] : false" v-if="!party_set[type]" >
        <i class="fas fa-caret-down mt-2 mx-2 text-gray-300"></i>
        <span class="" v-if="useProcessStore().process.document_data?.[type + '_name']">
          {{ useProcessStore().process.document_data?.[type + '_name'] }}
        </span>
      </a>
      <div class="is-flex p-3 w-full" v-if="party_set[type]"
        :class="{'is-flex-direction-row-reverse': type == 'origin'}">
        <a href="" @click.prevent="party_set[type] = false">
          <i class="fas fa-caret-up mt-2 mx-2"></i>
        </a>
        <div class="select is-small is-flex-grow-1">
          <select @change="save_document_party_return(type, party)" v-model="useProcessStore().process[type]"
            class="w-full">
            <option :value="null">Zgjidh magazine</option>
            <optgroup v-for="w_type in useProcessStore().warehouse_types.filter(t => {
              if(useProcessStore().process[type + '_type_facility'] != 0){
                return t.id == useProcessStore().process[type + '_type_facility']
              }
              else return true
            })" :key="w_type.id" :label="w_type.name">
              <option v-for="warehouse in useProcessStore().warehouses_list.filter(w => w.warehouse_facility == w_type.id)"
                :key="warehouse.guid" :value="warehouse.guid">
                {{ warehouse.name }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
    </div>
    <div v-if="useProcessStore().selected_warehouse[type]" class="p-4">
      <div class="is-flex justify-between border-b pl-2">
        <span class="font-thin">Adresa: </span>
        <span>{{ useProcessStore()?.selected_warehouse?.[type]?.location }}</span>
      </div>
      <div class="is-flex justify-between border-b pl-2">
        <span class="font-thin">Qyteti: </span>
        <span>{{ useProcessStore()?.selected_warehouse?.[type]?.city_name }}</span>
      </div>
      <div class="is-flex justify-between pl-2">
        <span class="font-thin">Menaxheri: </span>
        <span>{{ useProcessStore()?.selected_warehouse?.[type]?.manager_name }}</span>
      </div>
    </div>
  </div>

  <!-- SUPPLIER -->
  <div v-else-if="party == 3" class="text-gray-100 text-black is-flex is-justify-content-space-between is-align-items-center shadow-md shadow"
    :class="{'is-flex-direction-row-reverse': type == 'destination'}">
    <div class="font-bold is-uppercase is-flex is-align-items-center text-gray-100 p-3 border-r"
      :class="[type == 'origin' ? 'is-flex-direction-row-reverse border-r ': 'border-l']">
      <span> Furnitori </span>
      <i class="text-slate-400 fa-solid fa-truck mx-2"></i> 
    </div>
    <a href="" @click.prevent="useProcessStore().process?.document_data?.completed != 1 ? party_set[type] = !party_set[type] : null" v-if="!party_set[type]" class="p-3">
      <span v-if="useProcessStore().process.document_data?.[type + '_name']" class="mx-2 text-black">
        {{useProcessStore().process.document_data?.[type + '_name']}}
      </span>
      <i class="fas fa-caret-down mt-2 mx-2 text-gray-300"></i>
      
    </a>
    <div class="is-flex p-3 w-full" v-if="party_set[type] && useProcessStore().process?.document_data?.completed != 1"
      :class="{'is-flex-direction-row-reverse': type == 'destination'}">
      <div class="select is-small is-flex-grow-1">
        <select @change="save_document_party_return(type, party)" v-model="useProcessStore().process[type]"
          class="w-full">
          <option :value="null">Zgjidh furnitorin</option>
            <option v-for="supplier in useProcessStore().suppliers_list"
              :key="supplier.guid" :value="supplier.guid">
              {{ supplier.name }}
            </option>
        </select>
      </div>
      <a href="" @click.prevent="party_set[type] = false">
        <i class="fas fa-caret-up mt-2 mx-2"></i>
      </a>
    </div>
  </div>

  <!-- CUSTOMER -->
  <div v-else-if="party == 4" class="bg-gray-500 is-flex is-justify-content-space-between is-align-items-center shadow-md shadow"
    :class="{'is-flex-direction-row-reverse': type == 'destination'}">

    <div class="font-bold is-flex is-align-items-center is-uppercase p-3 border-l text-gray-100"
      :class="[type == 'origin' ? 'is-flex-direction-row-reverse border-r ': 'border-l']">
      <a @click.prevent="useProcessStore().getCustomers()" href="" class="text-blue-100">Kliente</a>
      <i class="text-blue-100 fa-solid fa-user mx-2"></i>
      <i @click="useProcessStore().createCustomer()" class="fa-solid fa-plus ml-2 cursor-pointer"></i>
    </div>
    <a href="" @click.prevent="party_set[type] = useProcessStore().process?.document_data?.completed != 1 ? !party_set[type] : false; useProcessStore().getCustomers()" 
    v-if="!party_set[type]" class="p-3 text-white">
      <i class="fas fa-caret-down mt-2"></i>
      <span class="mx-3" >{{ useProcessStore().process.document_data?.[type + '_name'] }}</span>
    </a>

    <div v-if="useProcessStore().customers_list.length > 0 && party_set[type] == true" class=" is-flex p-3 w-full"
      :class="{'is-flex-direction-row-reverse': type == 'destination'}">
      <div class="select is-small is-flex-grow-1" v-if="!useProcessStore().customers_search">
        <select @change="save_document_party_return(type, party)" v-model="useProcessStore().process[type]" name="" id=""
          class="w-full">
          <option :value="null">Zgjidh klient</option>
          <!-- create optgroup based on type_id_name --> 
          <optgroup v-for="type_id in useProcessStore().grouped_customers" :key="type_id.id" :label="type_id.name">
            <option v-for="customer in useProcessStore().customers_list.filter(c => c.type_id == type_id.id)" :key="customer.guid" :value="customer.guid">
              {{ customer.name }}
            </option>
          </optgroup>
          <!-- <option v-for="customer in useProcessStore().customers_list" :key="customer.guid" :value="customer.guid"> -->
          <!--   {{ customer.name }} -->
          <!-- </option> -->
        </select>
      </div>
      
      <input type="text" v-model="useProcessStore().customers_search_val" 
          class="input is-small is-flex-grow-1"
          placeholder="Kerko klient" v-if="useProcessStore().customers_search">

      <a href="" @click.prevent="customers_search_toggle" class="text-blue-100">
        <i class="fas fa-search mt-2 mx-2" v-if="!useProcessStore().customers_search"></i>
        <i class="fas fa-times mt-2 mx-2" v-if="useProcessStore().customers_search"></i>
      </a>
    </div>
  </div>

  <div class="select is-small is-flex-grow-1 w-full " v-if="useProcessStore().customers_search && party == 4">
    <select @change="save_document_party_return(type, party)" v-model="useProcessStore().process[type]" name="" id=""
      class="w-full border-green-100 border-2">
      <option :value="null">Zgjidh klient</option>
      <!-- create optgroup based on type_id_name --> 
      <optgroup v-for="type_id in useProcessStore().grouped_customers" :key="type_id.id" :label="type_id.name">
        <option v-for="customer in useProcessStore().customers_list
          .filter(c => c.type_id == type_id.id && JSON.stringify(c).toLowerCase().includes(useProcessStore().customers_search_val.toLowerCase()))" 
        :key="customer.guid" :value="customer.guid">
          {{ customer.name }}
        </option>
      </optgroup>
      <!-- <option v-for="customer in useProcessStore().customers_list" :key="customer.guid" :value="customer.guid"> -->
      <!--   {{ customer.name }} -->
      <!-- </option> -->
    </select>
  </div>
  <div>
    <div v-for="att in useProcessStore().attributes[type]" :key="att.attribute_id"
    class="is-flex justify-between border px-2 py-1 border-gray-200 items-center attr-bg">
      <span class="uppercase is-size-7">{{att.attribute_name}} </span>
      <span v-if="att.type_id == 'text'">{{att.attribute_value}}</span>
      <span v-else-if="att.type_id == 'number'">{{att.attribute_value}}</span>
      <span v-else-if="att.type_id == 'checkbox'">
        <i class="fas" :class="[att.attribute_value == 1 ? 'text-green-500 fa-circle-check' : 'text-gray-300 fa-times']"></i>
      </span>
    </div>
  </div>
  <!-- BLOCK: Create Customer Modal Form -->
  <div
    class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-top justify-center create-customer"
    style="z-index:99;"
    v-if="useProcessStore().toggleCreateCustomer" @click.self="useProcessStore().toggleCreateCustomer = false">
    <DaForm v-if="useProcessStore().toggleCreateCustomer"
      class="bg-white p-1 rounded-md shadow-lg text-left mt-3" 
      @submitted="submitCustomer"
      style="max-height: 80vh; overflow: scroll;"
      :form="useProcessStore().customer_create_form">
    </DaForm>
  </div>
</template>

<script setup>

import { defineProps, reactive, inject, onBeforeMount } from 'vue'
import { useProcessStore } from '@/stores/process'
import CustomersServices from "@/services/Customer";
import CategoriesServices from "@/services/Category";
import WarehousesServices from "@/services/Warehouse";

import DaForm from "@/components/daform/index.vue";
// import CustomerCreateForm from "@/pages/customers/forms/create.js";

const props = defineProps(['party', 'type'])
var party_set = reactive({ origin: false, destination: false })
// useProcessStore().grouped_customers = reactive([])
const toast = inject("toast");
useProcessStore().customers_search = false

// async function getCustomers() {
//   useProcessStore().customers_search = false
//   useProcessStore().customers_list = await CustomersServices.getCustomers();
//   // group by type_id into useProcessStore().grouped_customers
//   useProcessStore().customers_list.map(a => {
//     if(!useProcessStore().grouped_customers.find(b => b.id == a.type_id)){
//       useProcessStore().grouped_customers.push({id: a.type_id, name: a.type_id_name})
//     }
//   })
// }

function customers_search_toggle() {
  useProcessStore().customers_search = !useProcessStore().customers_search
}

const submitCustomer = async (form) => {
  var new_customer = await CustomersServices.createCustomer(form)
  // await useProcessStore().getCustomers()
  party_set[props.type] = useProcessStore().process?.document_data?.completed != 1 ? !party_set[props.type] : false
  await useProcessStore().getCustomers()
  useProcessStore().customers_list.push(new_customer)
  useProcessStore().toggleCreateCustomer = false
  useProcessStore().process[props.type] = new_customer.guid
  save_document_party_return()
}

function save_document_party_return() {
  useProcessStore().save_document_party(props.type, props.party)
    .then(async () => {
      //get attributes
      if(props.party == 2){
        // Get single waregouse data
        useProcessStore().selected_warehouse[props.type] = await WarehousesServices.getWarehouseData(useProcessStore().process[props.type])
      }
      if(props.party == 4){
        var selected_category = {}
        var customer = {}
        useProcessStore().customers_list.map(a => {
          if(useProcessStore().process[props.type] == a.guid){ 
            selected_category[a.type_id] = true
            customer = a
          }
        });
        useProcessStore().attributes[props.type] = await CategoriesServices.getCategoryAttributes(selected_category, customer)
      }


      toast.show("Ndryshimi u ruajt me sukses.", {
        ...{ type: "success", duration: 4000 },
      });
    }) 
}
onBeforeMount(async () => {
  if(props.party == 2){
    // Get single waregouse data
    useProcessStore().selected_warehouse[props.type] = await WarehousesServices.getWarehouseData(useProcessStore().process[props.type])
  }
  if(props.party == 4){
    var customer = {guid: useProcessStore()?.process.document_data?.[props.type]}
    var category = {}
    category[useProcessStore()?.process.document_data?.[props.type + '_type_id']] = true
    useProcessStore().attributes[props.type] = await CategoriesServices.getCategoryAttributes(category, customer)
  }
});

</script>

<style>
.attr-bg {
  background: #ffffd5;
}
</style>
