<template>
    <div class="relative">
        <div class=" absolute top-1 left-0 bg-white is-flex is-flex-direction-column border rounded p-1" 
            style="z-index:999; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
            <div class="is-flex pl-2">
                <div v-if="product.services_categories && product.services_categories.length > 0">
                    <div v-for="service_type in product.services_categories" :key="service_type.guid" 
                        class="mr-2 pr-2">
                        <div class="m-0 is-flex is-justify-content-space-between border-b w-full pb-1">
                            <span class="font-bold pr-3">
                                {{service_type.attribute_name}}
                            </span>
                            <div class="is-flex is-align-items-center relative">
                                <input v-if="service_type.show_search" 
                                    v-model="service_type.search_val" 
                                    @keyup.enter="useProcessStore().search_service_type(service_type, product, product.services.filter(s => s.guid == service_type.service_type_id)[0])"
                                    type="text" 
                                    class="input is-small" placeholder="Kerko">

                                <i @click="service_type.show_search = !service_type.show_search" class="fa-solid fa-magnifying-glass ml-2 is-clickable"></i>
                                <div v-if="service_type.show_search && product.services_search_result && product.services_search_result[service_type.service_type_id]" 
                                    class="absolute right-0 top-7 border rounded bg-white p-2"
                                    style="z-index: 99999; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
                                    <div class="is-flex border-b mb-2">
                                        <span>Te tjera ...</span>
                                        <button @click.prevent="service_type.show_search = !service_type.show_search" 
                                            class="delete is-small ml-auto"></button>
                                    </div>
                                    <div v-for="result in product.services_search_result[service_type.service_type_id]" :key="result.guid">
                                        <div @click="save_service_item_click(product, result, document_id)"
                                            class="is-clickable" style="width: max-content;">
                                            <span class="is-flex is-justify-content-space-between is-align-items-center">
                                                <span>
                                                    {{result.service_name}} 
                                                </span>
                                                <span class="text-sm ml-2 text-slate-400">
                                                    {{useProcessStore().price_filter(result.service_price)}}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label v-for="service in product.services.filter(s => s.guid == service_type.service_type_id)" 
                            :key="service.guid" 
                            @click="save_service_item_click(product, service, document_id)"
                            class="checkbox py-1 is-flex is-align-items-flex-start" style="width: max-content;">
                        
                            <span class="is-flex is-justify-content-space-between">
                                <span>
                                    {{service.service_name}} 
                                </span>
                                <span class="text-sm ml-2 text-slate-400">
                                    {{useProcessStore().price_filter(service.service_price)}}
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
                <div v-else style="width: max-content;">
                    Ky artikull nuk ka sherbine te percaktuara
                </div>
            </div>
        </div>
        <!-- <pre>{{process}}</pre> -->
    </div>
</template>

<script setup>

    import { useProcessStore } from '@/stores/process'
    import { onMounted, defineProps } from 'vue'
    import Api from "@/services/Api";
    import { inject } from "vue";
    import { useRoute } from 'vue-router'
    const route = useRoute()
    const toast = inject("toast");
    const props = defineProps(['product'])
    

    onMounted(async () => {
        await Api(true).post("/search/expanded/data", {
            product: props.product
        }).then(res => {
            useProcessStore().product_list = [...useProcessStore().product_list.map(p => {
                if(p.detail_id == props.product.detail_id){
                    p.services = res.data.services
                    p.services_categories = res.data.services_categories
                }
                
                return p
            })]
        })
    })
    function save_service_item_click(product, service, document_id) {
        useProcessStore().save_item_service(product, service, document_id).then(() => {

            // Get the document 
            useProcessStore().get_process_document(route.params.document_id)

            // Toast success
            toast.show('Ndryshimi u ruajt me sukses.', { ...{ type: 'success', duration: 4000 } });

            // Close search result if is open - services_categories.show_search = false
            useProcessStore().product_list = [...useProcessStore().product_list.map(p => {
                if(p.detail_id == props.product.detail_id){
                    if(p.services_categories){
                        p.services_categories = p.services_categories.map(s => {
                            if(s.show_search) s.show_search = false
                            return s
                        })

                    }
                }
                return p
            })]
        })
    }

</script>

<style>

</style>
