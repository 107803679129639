<template>
    <div>
        <div v-if="useSearchProcess().no_search_results" class="has-text-centered my-3">
            Nuk ka rezultate nga kerkimi.
        </div>
        <!-- <pre>{{useSearchProcess().search_results}}</pre> -->
        <div v-if="useSearchProcess().search_results.length > 0">
            <div v-for="(type, index) in Object.keys(useSearchProcess().grouped_search_results)" :key="index">
                <p class=" p-1 mb-1 is-capitalised text-white" style="">{{useSearchProcess().translation[type]}}</p>
                <div v-for="result in useSearchProcess().grouped_search_results[type]" :key="result.guid"
                    class="mb-2 p-1 text-white border-b  border-slate-500">
                    <article class="media text-white">
                        <div v-if="useProcessStore().current_step.adds_items > 0 || useProcessStore().current_step.adds_services == 1" 
                            @click="useSearchProcess().toggle_expand_result(result, useProcessStore().selected_pos)" 
                            class="is-align-self-center mr-2 is-clickable">
                            <i v-if="!result.expanded" class="fa-solid fa-chevron-down"></i>
                            <i v-else class="fa-solid fa-chevron-up"></i>
                        </div>
                        <figure class="media-left">
                            <p class="image is-64x64">
                                <img src="https://bulma.io/images/placeholders/128x128.png">
                            </p>
                        </figure>
                        <div class="media-content">
                            <div class="content ">
                                <div class="is-flex is-flex-direction-column">
                                    <div class="pb-1">
                                        <div class="text-gray-100 text-xs">{{result.category_name}}</div>
                                        <strong class=" mr-2 text-white ">{{ result.name}}</strong> 
                                    </div>
                                    <!-- <span> {{result.description}} </span> -->
                                     <div class="pt-1 relative" v-if="result.has_exclusive_accessories  > 0 && useProcessStore().current_step.adds_accessories == 1">
                                        <div @click="toggle_dropdown('accessory', result)" class="tags has-addons is-clickable mb-0">
                                            <span class="tag is-primary">Aksesore ekskluziv</span>
                                            <span class="tag">{{result.has_exclusive_accessories}}</span>
                                        </div>
                                        <!-- Exclusive dropdown -->
                                        <exclusive-dropdown
                                            v-if="result.show_exclusive_dropdown"
                                            :is_visible="result.show_exclusive_dropdown"
                                            :entity="exclusive_dropdown_entity"
                                            :selected_product="selected_product"
                                            :location="'search'"
                                            @close_exclusive_dropdown="close_exclusive_dropdown"
                                        ></exclusive-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="media-right">
                            <div class="is-flex py-2 pl-2 pr-0 add-prod-blocks" >
                                <div v-if=" ( result.extra_services.length > 0 || result.extra_accessories.length > 0 || result.extra_exclusive_accessories.length > 0)"
                                    class="is-flex is-flex-direction-column p-2 has-text-centered">
                                    <span class="text-xs">
                                        Aksesoreve dhe sherbimeve
                                    </span>
                                    <span>
                                        {{useProcessStore().price_filter(useProcessStore().extras_total(result))}}
                                    </span>
                                </div>
                                <div class="is-flex is-flex-direction-column p-2 mx-3 has-text-centered">
                                    <span class="text-xs text-sky-500 uppercase font-mono">
                                        Gjendja
                                    </span>
                                    <span>
                                      <small>{{useProcessStore().amount_filter(result.stock_amount)}}</small>
                                      <small class="ml-2 text-yellow-300" 
                                        v-if="result.in_process_amount > 0"
                                        >-{{useProcessStore().amount_filter(result.in_process_amount)}}</small>
                                    </span>
                                </div>
                                <div class="is-flex is-flex-direction-column p-2 mx-3 has-text-centered w-28">
                                    <span class="text-xs text-sky-500 uppercase font-mono">
                                        Cmimi
                                    </span>
                                    <span>
                                        {{useProcessStore().price_filter(result.price)}}
                                    </span>
                                </div>
                                <div class="p-2 is-flex is-align-items-center">
                                    <a @click.prevent="add_to_cart(result)" class="button bg-green-600 text-white" 
                                      href="#">
                                      <i class="fa fa-cart-plus mr-2"></i>  Shto ne liste
                                    </a>
                                </div>
                            </div>
                        </div>
                    </article>
                    <div v-if="result.expanded " class=" mt-2 p-2" style="">
                        <div v-if="result.expanded_loading" class="text-center">
                            <i class="fa fa-spinner fa-spin mr-2"></i>
                        </div>
                        <div v-else class="is-flex">
                            <div class="w-1/5 pr-2">
                                <p class="">Atribute</p>
                                <table v-if="result.attributes.length > 0" class="table  is-narrow is-fullwidth attr-table bg-transparent">
                                    <thead>
                                        <tr>
                                            <td class="text-xs">Specifika</td>
                                            <td class="text-xs">Vlera</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="att in result.attributes" :key="att.guid ">
                                            <td class="text-sm">{{att.attribute_name}}</td>
                                            <td>{{att.attribute_value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p v-else>
                                    Nuk ka atribute
                                </p>
                            </div>
                            <div v-if="useProcessStore().current_step.adds_items == 2 && useProcessStore().current_step.adds_accessories" class="is-flex-grow-1">
                                <p class="">Aksesore</p>
                                <label v-for="acc in result.accessories" :key="acc.guid" 
                                    @click.prevent="useSearchProcess().add_extra(result, acc, 'accessories')"
                                    class="is-flex mb-2 px-2 py-1 checkbox border rounded hover:text-white " style="">
                                    <div class="is-flex-grow-1 is-flex is-flex-direction-column" >
                                        <div>
                                            <label  class="">
                                                <i v-if="acc.is_checked" class="fa-regular fa-square-check"></i>
                                                <i v-else class="fa-regular fa-square"></i>
                                                <span class="ml-1">
                                                    {{acc.name}}
                                                </span>
                                            </label>
                                        </div>
                                        <p>
                                            <small>{{acc.description}}</small>
                                        </p>
                                    </div>
                                    <div>
                                        {{useProcessStore().price_filter(acc.price)}}
                                    </div>
                                </label>
                                <p v-if="result.accessories == 0" class=""> Nuk ka aksesore </p>
                            </div>
                            <div v-if="useProcessStore().current_step.adds_services == 1" class="is-flex-grow-1 pl-2">
                                Sherbime
                                <label v-for="serv in result.services" :key="serv.guid" 
                                    @click.prevent="useSearchProcess().add_extra(result, serv, 'services')"
                                    class="is-flex mb-2 px-2 py-1 checkbox border rounded hover:text-white " style="">
                                    <div class="is-flex-grow-1 is-flex is-flex-direction-column" >
                                        <div>
                                            <label class="">
                                                <i v-if="serv.is_checked" class="fa-regular fa-square-check"></i>
                                                <i v-else class="fa-regular fa-square"></i>
                                                <span class="ml-1">
                                                    {{serv.service_name}}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        {{useProcessStore().price_filter(serv.service_price)}}
                                    </div>
                                </label>
                                <p v-if="result.services == 0" class=""> Nuk ka sherbime </p>
                            </div>
                        </div>
                    </div>
                    <!-- <pre>{{result}}</pre> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useSearchProcess } from '@/stores/search_process'
    import { useProcessStore } from '@/stores/process'
    import { inject, ref } from "vue";
    const toast = inject("toast");
    // exclusive-dropdown
    import exclusiveDropdown from '@/components/exclusive_dropdown.vue';

    // var exclusive_dropdown_visible = ref(false)
    var exclusive_dropdown_entity = ref('')
    var selected_product = ref({})

    async function add_to_cart(result) {
        await useProcessStore().add_product_to_list({...result, amount: 1})
        .then(res => {
            
            // Backup for refresh - Working
           if(useProcessStore().is_new)
                // console.log('ccc')
                window.location.href = '/process/document/' + useProcessStore().process.guid + '/' + res.data.document
            else{
                //close search results
                useSearchProcess().show_search_results = false
                useSearchProcess().search_val = ''

                // Get all document items
                useProcessStore().get_process_document(res.data.document)

                // Toaster
                toast.show('Produkti u shtua me sukses.', { ...{ type: 'success', duration: 4000 } });

           }
        })
    }
    function toggle_dropdown(entity, data){
        // exclusive_dropdown_visible.value = !exclusive_dropdown_visible.value

        useSearchProcess().search_results = useSearchProcess().search_results.map(sr => {
            if(sr.product_id == data.product_id)
                sr.show_exclusive_dropdown = !sr.show_exclusive_dropdown
            else
                sr.show_exclusive_dropdown = false
            return sr
        })
        exclusive_dropdown_entity.value = entity
        selected_product.value = data
    }
      function close_exclusive_dropdown(data){
        toggle_dropdown('accessory', data.product)
    }
</script>
<style scoped>
    .attr-table > thead > tr > td, .attr-table > tbody > tr > td{
        /* background:#646f9b;  */
        color:#fff;
    }
    .add-prod-blocks > div {
        /* background: #9aa3c7; */
        /* margin:; */
        /* height:100%; */
    }

    /* #bac1dd */

    /* #9aa3c7 */
    /* #646f9b */
</style>
