<template>
  <div class="process-main-wrap">
    <div class="is-flex p-2 bg-gray-100 border border-gray-200 mb-2 rounded-t-md shadow-md shadow items-center" :class="[
      useProcessStore().process.origin_type && !useProcessStore().process.destination_type ? 'rounded-br-md' : '',
      !useProcessStore().process.origin_type && useProcessStore().process.destination_type ? 'rounded-bl-md' : '',
    ]">
      <span>
        <span class="font-mono">

          <a class="ml-3" :href="'/process/' + useProcessStore().process?.guid + '/list'">
            {{ useProcessStore().process.name }}
          </a>
          <span class="font-extrabold">
            • {{ useProcessStore().current_step?.name }}
          </span>
        </span>
        <span v-if="useProcessStore().process?.document_data"
          class="text-green-800 font-bold rounded-full bg-green-200 px-2 py-1 ml-4">
          {{ useProcessStore().process?.document_data?.process_number }}
        </span>
        <i class="fa fa-user fa-xs text-gray-400 ml-4 mr-2"></i>
        <span class="text-gray-400 text-sm">{{ useProcessStore().process?.document_data?.created_by_name }}</span>
        <i class="fa fa-calendar fa-xs text-gray-400 ml-2 mr-2"></i>
        <span class="text-gray-400 text-sm">{{ helpers.dateFormatter(useProcessStore().process?.document_data?.created_at)
        }}</span>
      </span>
      <span v-if="useProcessStore().process?.document_data?.completed == 1"
        class="ml-auto border border-green-600 rounded-full bg-green-500">
        <i class="fa fa-check m-2 text-white"></i>
      </span>
      <span v-else class="ml-auto is-flex font-mono font-extrabold text-gray-400">
        {{ useProcessStore().current_step_index + 1 }} ∷ {{ useProcessStore().process_steps.length }}
      </span>
    </div>
    
    <div class="is-flex is-justify-content-space-between mb-2 flex-row sm:flex-column md:flex-row">
      <div v-if="useProcessStore().process.origin_type">
        <party-component v-if="useProcessStore().process.origin_type" :party="useProcessStore().process.origin_type"
          :type="'origin'"></party-component>
      </div>
      <!-- <div class="text-red-500 is-align-self-center" v-else>E PAPERCAKTUAR</div> -->
      <div class="border-slate-100 ml-auto w-1/2">
        <party-component v-if="useProcessStore().process.destination_type"
          :party="useProcessStore().process.destination_type" :type="'destination'"></party-component>
      </div>
    </div>

    <!--search  -->
    <search-index></search-index>
    <div style="flex-grow: 1;">
      <div class="single-step-wrap">
        <!-- Product list -->
        <div style="flex-grow: 1;" class="is-flex is-flex-direction-column">
          <div class="">
            <product-list></product-list>
          </div>
          <div class="is-flex">
            <div>
              <!-- <strong class="mt-3 ml-3 is-flex-grow-1">
                Total
              </strong> -->
            </div>
            <!-- BLOCK: totals document -->
            <div class="ml-auto w-2/5">
              <div v-if="useProcessStore().product_list.length > 0 && useProcessStore().current_step.can_see_price"
                class="">
                <document-total></document-total>
              </div>
            </div>
          </div>
        </div>
        <!-- BLOCK: step disputes -->
        <step-disputes v-if="useProcessStore().process?.document_data?.completed != 1"></step-disputes>
        <span v-else
          class=" font-bold  border-t w-full text-center px-4 py-1 "
          :class="[useProcessStore().process?.document_data?.with_rejection ? 'border-red-700 bg-red-50 text-red-700' : 'border-emerald-700 bg-green-50 text-emerald-700']">
          <div class="flex items-center justify-center">
            <div class="border  rounded-full  mr-5"
              :class="[useProcessStore().process?.document_data?.with_rejection  ? 'border-red-400 bg-red-500' : 'border-green-400 bg-green-500']">
              <i v-if="useProcessStore().process?.document_data?.with_rejection" class="fa fa-xmark fa-2x m-2 text-white"></i>
              <i v-else class="fa fa-check fa-2x m-2 text-white"></i>
            </div>
            <div class="flex flex-col">
              <span v-if="useProcessStore().process?.document_data?.with_rejection">
                DOKUMENTI ESHTE I REFUZUAR
              </span>
              <span v-else>
                DOKUMENTI ESHTE I PERFUNDUAR
              </span>
              <div class="text-xs font-light text-black">
                <i class="fa fa-user fa-xs text-gray-400"></i>
                {{ useProcessStore().process?.document_data?.completed_by_name }}
                <i class="fa fa-calendar ml-3 fa-xs text-gray-400"></i>
                {{ helpers.dateFormatter(useProcessStore().process?.document_data?.completed_at) }}
              </div>
              <div v-if="useProcessStore().process?.document_data?.with_rejection"
                class="text-14px font-normal">
                Refuzuar: {{useProcessStore().process?.document_data?.rejection_name}}
              </div>
              <div v-if="useProcessStore().process?.document_data?.with_text"
                class="text-14px font-normal">
                Shenime per refuzimin: {{useProcessStore().process?.document_data?.with_text}}
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useProcessStore } from '@/stores/process'
import { getCurrentInstance, onBeforeMount } from "vue";
import partyComponent from './components/party-component'
import documentTotal from './components/document-total'

import searchIndex from '@/pages/process/components/search/index.vue';
import stepDisputes from '@/pages/process/components/step_disputes.vue';
import productList from '@/pages/process/components/product_list/index.vue';
import helpers from "@/helpers.js";
// Get first step


onBeforeMount(() => {
  // BLOCK: add page title on page open 
  getCurrentInstance().appContext.config.globalProperties.$document.title = useProcessStore().process?.name?.toUpperCase() + ' • ' + useProcessStore().current_step?.name?.toUpperCase() + (
    useProcessStore().process?.document_data?.id ? ' #' + useProcessStore().process?.document_data?.id : ' - DOKUMENT I RI'
  )
})

const route = useRoute();

// watch for route changes to the process_id
watch(() => route.params.process_id, (process_id) => {
  // BLOCK: get process data
  useProcessStore().get_process_steps(process_id, 0);
});

</script>

<style scoped>
.single-step-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.process-main-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}</style>
