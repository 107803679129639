<template>
    <div class="absolute bg-white pl-2 pb-2 pt-3 pr-3 rounded " style="z-index: 999; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; z-index:99999; width: max-content;">
        <div class="absolute right-0 top-0">
            <button @click.prevent="close_dropdown()" class="delete is-small"></button>
        </div>
        <div v-if="!loading">
            <div v-if="product.available_accessories && product.available_accessories.length > 0">
                <!-- <pre> {{product.available_accessories}} </pre> -->
                <div v-for="(accessory, index) in product.available_accessories" :key="index"
                    @click.prevent="add_accessory(accessory)"
                    class="border rounded p-1 is-clickable">
                    <div class="is-flex w-full">
                        <div class="is-flex-grow-1 is-flex is-flex-direction-column">
                            <div> {{accessory.name}} </div>
                            <div> {{accessory.description}} </div>
                        </div>
                        <div class="pl-3 is-align-self-center">
                            <span class="bg-teal-100 px-1 rounded">
                                {{accessory.price}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <span></span>
                Ky artikull nuk ka aksesore.
            </div>
        </div>
        <div v-else>
            <i class="fa-solid fa-spinner fa-spin"></i>
        </div>
    </div>
</template>

<script setup>
  import { useProcessStore } from '@/stores/process'
//   import { useSearchProcess } from '@/stores/search_process'
</script>

<script>
export default {
    props:['product'],
    async created(){
        this.loading = true
        await useProcessStore().get_product_accessories(this.product)
        this.loading = false
    },
    data() {
        return {
            loading: false,
            
        }
    },
    computed: {
        // accessories_list(){
        //     var a = []
        //     if(!this.loading){
        //         a = useProcessStore().product_list.filter(p => p.detail_id == this.product.detail_id)
        //     }

        //     return a
        // }
    },
    methods: {
        close_dropdown() {
            this.$emit("toggle_accessories_dropdown_1", { product: this.product})
        },
        async add_accessory(product){
            this.loading = true
            var a = product
            a.reference_id = this.product.detail_id
            await useProcessStore().add_product_to_list(a)
            .then(async () => {
                // Get all items 
                await useProcessStore().get_process_document(this.product.document_id)
                this.$toast.success('Aksesori u shtua me sukses')
            })
            this.loading = false
        }
        
    },
}
</script>

<style>

</style>