<template>
  <div class="footer-pre-wrap" v-if="!processing">
    <div v-if="useProcessStore().product_list.length > 0" class="is-flex is-align-items-flex-end pl-2 pb-2">
      <reject-process></reject-process>
      <process-print v-if="useProcessStore().current_step.print_document"></process-print>
      <process-print-warranty v-if="useProcessStore().current_step.gives_warranty"></process-print-warranty>
      <product-scan-modal v-if="useProcessStore().current_step.ask_serial == 1"></product-scan-modal>
    </div>
    <div class="is-flex is-justify-content-flex-end bg-white is-pulled-right bg-slate-50 step-disputes-wrap ml-auto">
      <!-- BLOCK: LIST OF DISPUTES -->
      <div v-if="isUserAllowedToStep()">
        <div v-for="dispute in useProcessStore().current_step.disputes" :key="dispute.guid"
          class="p-4 border border-slate-200 is-flex is-flex-direction-column">
          <a class="button is-small w-full mb-1" @click.prevent="() => { }" href="">
            {{ dispute.name }}
          </a>
          <small v-if="dispute.description" class="mb-1 is-size-7">
            <i class="fa-solid fa-circle-info mr-1 text-slate-400"></i>
            {{ dispute.description }}
          </small>
          <small v-if="dispute.is_required" class="is-size-7">
            <i class="fa-solid fa-triangle-exclamation mr-1 text-slate-400"></i>
            Hap i detyrueshem
          </small>
          <small v-if="dispute.needs_confirmation" class="is-size-7">
            <i class="fa-solid fa-check-to-slot mr-1 text-slate-400"></i>
            Pret konfirmim
          </small>
        </div>
      </div>


      <!-- BLOCK: payment terms / methods -->
      <!-- shows only if the step creates invoices -->
      <div v-if="useProcessStore().current_step.creates_invoices"
        class="p-4 border border-slate-400 is-flex is-flex-direction-column mr-1 w-56 bg-gradient-to-t from-gray-50 to-sky-600">
        <div class="text-xs text-white text-right border-b border-blue-500">
          PAGESA
        </div>
        <div class="is-flex is-flex-direction-column mt-2">
          <label class="text-sky-200 text-xs flex justify-between">
            <i class="fa fa-credit-card"></i>
            MENYRA 
          </label>
          <div class="select is-small">
            <select v-model="useProcessStore().process.payment_method">
              <option v-for="method in useProcessStore().payment_methods" :key="method.guid" 
                :value="method.guid">{{ method.name }}</option>
            </select>
          </div>
        </div>
        <div class="is-flex is-flex-direction-column mt-2 border-t pt-1 border-gray">
          <label class="text-sky-700 text-xs flex justify-between">
            <i class="fa fa-calendar"></i>
            AFATI
          </label>
          <div class="select is-small">
            <select v-model="useProcessStore().process.payment_term">
              <option v-for="term in useProcessStore().payment_terms" :key="term.guid" 
                :value="term.guid">{{ term.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="p-1 border border-slate-400 is-flex is-flex-direction-column bg-stone-200 justify-between"
        v-if="isUserAllowedToStep()">
        <!-- LOGIC:  
              1. user should be allowed to the step 
              2. there should be at least one next step (TO SEE!!!) 
              v-if="(useProcessStore().process.next_step && useProcessStore().process?.next_step?.length > 0) || useProcessStore().current_step.sort_nr == 0"
        -->
        <div v-if="exceed_amount_check" class="flex">
          <div class="w-96">
            <i class="fa-solid fa-triangle-exclamation ml-2"></i>
            Kur totali e kalon shifren 500.000 Leke, eshte e detyrueshme te vendoset numri i dokumentit te klientit.
          </div>
          <div class="flex flex-col">
            <div class="mb-2">
              <input 
                v-model="useProcessStore().new_customer_id" 
                type="text" 
                placeholder="Nr. Dokumenti"
                class="border rounded px-2 text-14px">
            </div>
            <div class="flex">
              <a @click.prevent="useProcessStore().save_customer_id()" href="" class="w-full bg-green-200 border rounded text-center">
                Ruaj ID
              </a>
            </div>
          </div>
        </div>
        <div v-else>
          <div class=" " v-if="can_be_completed">
            <a @click.prevent="saveDocumentAndContinue()"
              class="shadow-lg button is-small p-4 h-14 w-full is-flex  hover:bg-sky-700 hover:text-sky-50" href="">
              <div class="border-r border-gray-300 p-1 mr-2">
                <i class="fa fa-check mr-2 text-green-300 fa-2x"></i>
              </div>
              <span>
                <b>PERFUNDO {{ useProcessStore().current_step?.name }}</b>
                <!-- LOGIC: if there is a next step, show the name of the next step -->
                <span v-if="useProcessStore().process?.next_step?.length > 0">
                  dhe vazhdo tek
                  <b class="is-block is-size-6 border-blue-500 border-t text-right">
                    {{ useProcessStore().process.next_step[0].name }}
                    <i class="fa-solid fa-caret-right ml-2 text-gray-400"></i>
                  </b>
                </span>
                <!-- LOGIC: if there is no next step, show the name of the current step -->
                <b class="is-block is-size-6" v-else>
                  {{ useProcessStore()?.current_step?.name }}
                </b>
              </span>
            </a>
          </div>
          <div class=" " v-else>
            <a class="shadow-lg button is-small p-4 h-14 w-full is-flex bg-red-300 text-white" href="#">
              <div class="border-r border-gray-300 p-1 mr-2">
                <i class="fa fa-exclamation-triangle mr-2 text-red-800 fa-2x"></i>
              </div>
              <span class="text-black">
                <b>Kompletoni fushat e detyrueshme</b>
              </span>
            </a>
          </div>
        </div>

        <!-- BLOCK: status of completition of a service, shows only if the step is a service -->
        <div v-if="useProcessStore().process?.document_data">
          <div class="bg-sky-300 p-2" v-if="useProcessStore().current_step.creates_invoices">
            <label class="text-gray-600">
              <i class="fa fa-info mr-2"
                title="produktet qe ju keni te drejta te menaxhoni dhe qe permbushin kushtet do te faturohen"></i>
              Krijon fature
            </label>
          </div>
          <label class="text-white p-2" v-if="useProcessStore().current_step.is_service == 1">
            <input type="checkbox" v-model="useProcessStore().current_step.skip_this_step" true-value="1" false-value="0"
              class="mr-2"> Sherbimi i kerkuar pranohet
          </label>
          <div class="p-2 flex items-center justify-between uppercase" style="background: rgb(250 255 230)"
            v-if="useProcessStore().current_step.is_transaction > 0">
            <span class="mr-1 font-thin">
              <i class="fa fa-dolly text-red-500 mr-2" v-if="useProcessStore().current_step.is_transaction == 1"></i>
              <i class="fa fa-dolly-flatbed text-green-500 mr-2"
                v-else-if="useProcessStore().current_step.is_transaction == 2"></i>
              Transaksion
            </span>
            <b class="font-normal text-red-500" v-if="useProcessStore().current_step.is_transaction == 1">dalje</b>
            <b class="font-normal text-green-500" v-else-if="useProcessStore().current_step.is_transaction == 2">hyrje</b>
          </div>
          <div v-if="useProcessStore().process?.document_data.own_items_not_addressed == 1 &&
            useProcessStore().process?.document_data.first_step == 0" class="bg-green-50 text-gray-600 p-2 border-t">
            <i class="fa fa-circle text-green-400 mr-2"
              title="produktet qe ju keni te drejta te menaxhoni dhe qe permbushin kushtet do te adresohen"></i>
            Ka produkte qe do te adresohen
          </div>
        </div>

      </div>
      <div v-else class="p-4 bg-red-400 text-white">
        <i class="fa fa-lock text-white mr-2 fa-2x"></i>
        Ju nuk keni te drejta tek ky hap i procesit!
      </div>
    </div>
  </div>
  <div v-else class="flex items-center justify-center gap-1">
    <i class="fa fa-spinner fa-spin fa-2x"></i>
    Duke u procesuar...
  </div>
</template>

<script setup>
import { inject, ref, computed } from 'vue'
import { useProcessStore } from '@/stores/process'
import processPrint from './process_print/index.vue'
import processPrintWarranty from './process_warranty/index.vue'
import productScanModal from '@/pages/processes/components/product_scan_modal.vue'
import rejectProcess from '@/pages/process_new/components/reject_process.vue'
const toast = inject('toast')

const processing = ref(false)

// LOGIC: this function saves the document and continues to the next step 
// if the step is the last one it shows a message that the document is completed
async function saveDocumentAndContinue() {
  if (processing.value) {
    return
  }

  // Backup working for 
  if((!useProcessStore().process.payment_method || !useProcessStore().process.payment_term) && useProcessStore().current_step.creates_invoices) {
    toast.error('Ju lutem plotesoni te dhenat e pageses per te vazhduar', {position: 'top'})
    return
  }

  processing.value = true
  await useProcessStore().open_step()
    .then((resp) => {
      if (resp.data.success) {
        toast.success(resp.data.success)
      }
      else if (resp.data.error) {
        toast.error(resp.data.error)
      }

      // LOGIC: reload the page after 3 seconds 
      // to get again the document data from the server 
      // and to get the new step data 
      if (window.location.hash.includes('DONT_RELOAD_PAGE')) {
        return
      }
      // setTimeout(() => {
      //   window.location.reload()
      // }, 3000)

      // TODO: alternative solution should be instead of reloading the page 
    })
}

function isUserAllowedToStep() {
  // CHECK: if the user is allowed to the step
  return JSON.parse(localStorage.user_steps).includes(useProcessStore()?.current_step.guid)
}

// products_with_no_serials computed property 
var products_with_no_serials = computed(() => {
  return useProcessStore().product_list.filter(p => p.serial_number == null || p.serial_number == '')
})

// can_be_completed computed property 
var can_be_completed = computed(() => {
  if(useProcessStore().current_step.ask_serial == 1 && products_with_no_serials.value.length > 0) {
    return false
  }
  return true
})

// exceed_amount_check computed property
var exceed_amount_check = computed(() => {
  console.log(useProcessStore().process)
  if(
    useProcessStore().process.destination_type == 4 && 
    useProcessStore().document_overall_total > 500000 && 
    !useProcessStore().process.destination_is_business && 
    !useProcessStore().process.destination_personal_id 
  ) return true
  // else if(
  //   useProcessStore().process.destination_type == 4 && 
  // )
  return false
})

</script>

<style>
.step-disputes-wrap {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.footer-pre-wrap {
  position: sticky;
  right: 0px;
  bottom: 0px;
  width: 100%;
  margin-left: auto;
  display: flex;

}
</style>
